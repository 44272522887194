import React from 'react'
import itemServer from '../../../lib/server/item'

export const OrderGridItem = ({item, addItem}) => {
    return (
        <div className='flex items-center gap-2'>
            <img src={itemServer.image(item.id)} className='w-14 h-14 rounded-md object-cover object-center flex-shrink-0' alt="" />
            <div className='w-full h-fit'>
                <h2 className='text-lg font-medium text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 text-wrap p-0 m-0'>{item.title}</h2>
                <span className='text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 p-0 m-0'>₹ {item.price}</span>
            </div>
            <button onClick={addItem} className='w-10 h-10 flex-shrink-0 flex justify-center items-center text-xs font-bold text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded dark:bg-gray-700 hover:bg-gray-700 dark:hover:bg-gray-600 focus:outline-none focus:bg-gray-700 dark:focus:bg-gray-600'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
            </button>
        </div>
    )
}
