import React, { useContext, useEffect, useState } from 'react'
import { WaiterPageHeader } from '../../../components/pages/waiters/WaiterPageHeader'
import waiterServer from '../../../lib/server/waiter'
import { WaitersTable } from '../../../components/pages/waiters/WaitersTable';
import { TablePegination } from '../../../components/footers/TablePegination';
import { ToastContext } from '../../../contexts/ToastContext';

const itemsPerPage = 5;

export const Waiters = () => {

  const [searchTerm, setSearchTerm] = useState('')
  const [waitersData, setWaitersData] = useState([])
  const [filteredWaiters, setFilteredWaiters] = useState([])
  const [query, setQuery] = useState({start: 0, end: itemsPerPage})

  const [status, setStatus] = useState(1)

  const {toastSuccess, toastError} = useContext(ToastContext)

  const fetchWaiters = async (s = status) => {
    try{
      const response = await waiterServer.all(s)
      setWaitersData(response)
      const result = response.filter(item => item.name.toLowerCase().includes(searchTerm))
      setFilteredWaiters(result)
    }catch(e){
      console.log(e)
      toastError('Unable to fetch waiters! Please refresh the page.')
    }
      
  }

  useEffect(() => {
    (async () => {
        await fetchWaiters()
    })()
  }, [])

  const pageChangeHandler = (start, end) => {
    setQuery({start, end})
  }

  const onSearchHandler = (term) => {
    setSearchTerm(term)
    const result = waitersData.filter(item => item.name.toLowerCase().includes(term))
    setFilteredWaiters(result)
  }

  const onStatusChangeHandler = async (s) => {
    setStatus(s)
    await fetchWaiters(s)
  }

  const onDeleteHandler = async (waiter) => {
    const result = window.confirm('Are you sure to delete the waiter?')
    if(!result){
      return
    }
    try{
      await waiterServer.delete(waiter.id)
      await fetchWaiters()
      toastSuccess('Waiter deleted successfully!')
    }catch(e){
      console.log(e)
      toastError('Unable to delete waiter! Please try again.')
    }
  }

  const onRestoreHandler = async (item) => {
    try{
      await waiterServer.restore(item.id)
      await fetchWaiters()
      toastSuccess('Waiter restored successfully!')
    }catch(e){
      console.log(e)
      toastError('Unable to restore waiter! Please try again.')
    }
  }

  return (
    <section className="container p-6 mx-auto">
      <WaiterPageHeader count={waitersData.length} searchTerm={searchTerm} onSearch={onSearchHandler} status={status} onStatusChange={onStatusChangeHandler} />
      <WaitersTable waiters={filteredWaiters.slice(query.start, query.end)} onDelete={onDeleteHandler} onRestore={onRestoreHandler} />
      <TablePegination total={filteredWaiters.length} itemsPerPage={itemsPerPage} nextPage={pageChangeHandler} prevPage={pageChangeHandler}  />
    </section>
  )
}
