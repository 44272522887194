import React from 'react'
import waiterServer from '../../../lib/server/waiter'


const CardWaiter = ({waiter}) => {
    return (
        <div className='flex gap-2 py-2 items-center'>
            <img src={waiterServer.image(waiter.id)} alt="" className='w-8 h-8 rounded-full object-cover object-center flex-shrink-0' />
            <div className='w-full flex items-center justify-between'>
                <p className='font-medium'>{waiter.name}</p>
                <p className='text-green-600'>{waiter.orders_count} orders</p>
            </div>
        </div>
    )
}

export const AvilableWaitersCard = ({waiters}) => {
  return (
    <div className='px-8 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800'>
        <p className="text-lg font-bold text-gray-700 dark:text-white mb-6">Avilable Waiters</p>
        <div>
            {waiters.map(waiter => <CardWaiter key={waiter.id} waiter={waiter} />)}
        </div>
    </div>
  )
}
