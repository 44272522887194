import React, { useContext, useEffect, useState } from 'react'
import { DashboardHeader } from '../components/pages/dashboard/DashboardHeader'
import dashboardServer from '../lib/server/dashboard';
import { AvilableWaitersCard } from '../components/pages/dashboard/AvilableWaitersCard';
import { PendingOrdersCard } from '../components/pages/dashboard/PendingOrdersCard';
import { RecentTransactionsCard } from '../components/pages/dashboard/RecentTransactionsCard';
import { ToastContext } from '../contexts/ToastContext';

export const Dashboard = () => {

  const [pendingOrders, setPendingOrders] = useState([]);
  const [compltedOrders, setCompltedOrders] = useState([])
  const [waiters, setWaiters] = useState([]);
  const [payments, setPayments] = useState([])

  const [headerData, setHeaderData] = useState({})
  const {toastError} = useContext(ToastContext)

  const fetchData = async () => {
    try{
      // console.log('Fetching ...')
      const response = await dashboardServer.getData()
      // console.log(response)
      setWaiters(response.waiters)
      setPayments(response.payments)
      const responseOrders = response.orders
      const orders = {pending: [], completed: []}
      const hData = {
        completedOrders: 0,
        pendingOrders: 0,
        itemsServed: 0,
        paymentCollected: 0
      }
      for(let i in responseOrders){
        hData.itemsServed = hData.itemsServed + parseInt(responseOrders[i].items_sum_order_itemsquantity);
        if(responseOrders[i].status === 0){
          orders.pending.push(responseOrders[i])
        }else if(responseOrders[i].status === 1){
          orders.completed.push(responseOrders[i])
          hData.paymentCollected = hData.paymentCollected + responseOrders[i].final
        }
      }
      setPendingOrders(orders.pending)
      setCompltedOrders(orders.completed)
      hData.completedOrders = orders.completed.length
      hData.pendingOrders = orders.pending.length
      setHeaderData(hData)
    }catch(e){
      toastError('Unable to fetch data! Please refresh the page.')
      console.log(e)
    }
  }

  useEffect(() => {
    (async () => {
      await fetchData()
    })()
    const handler = setInterval(async () => {
      await fetchData()
    }, 3000)
    return () => clearInterval(handler)
  }, [])

  return (
    <section className="container p-6 mx-auto">
      <DashboardHeader headerData={headerData}  />
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6'>
          <AvilableWaitersCard waiters={waiters} />
          <PendingOrdersCard orders={pendingOrders} />
          <RecentTransactionsCard payments={payments} />
      </div>
    </section>
  )
}
