import React, { useContext, useEffect, useState } from 'react'
import { SearchContext } from '../contexts/SearchContext'
import dashboardServer from '../lib/server/dashboard'
// import { ToastContext } from '../contexts/ToastContext'
import { ItemsSearchResult } from '../components/search/ItemsSearchResult'
import { WaitersSearchResult } from '../components/search/WaitersSearchResult'
import { TablesSearchResult } from '../components/search/TablesSearchResult'

export const Search = () => {
    
    const {searchTerm} = useContext(SearchContext)
    // const {toastError} = useContext(ToastContext)
    const [searchResult, setSearchResult] = useState({items: [], waiters: [], tables: []})

    useEffect(() => {
        (async () => {
            try{
                const response = await dashboardServer.search(searchTerm)
                setSearchResult(response)
                // console.log(response)
            }catch(e){
                // toastError('Unable to perform search! Please try again.')
                // console.log(e)
            }
        })()
    }, [searchTerm])


  return (
    <section className="container p-6 mx-auto">
        {searchResult.items.length === 0 && searchResult.waiters.length === 0 && searchResult.tables.length === 0 ?
            <p className='py-12 text-center font-medium text-zinc-500'>No search result found</p> : null}
        {searchResult.items.length > 0 ? <ItemsSearchResult items={searchResult.items} /> : null}
        {searchResult.waiters.length > 0 ? <WaitersSearchResult waiters={searchResult.waiters} /> : null}
        {searchResult.tables.length > 0 ? <TablesSearchResult tables={searchResult.tables} /> : null}
    </section>
  )
}
