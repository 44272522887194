import React from 'react'
import { getTimeAgo } from '../../../lib/helpers/dates'


const CardPayment = ({payment}) => {
    return (
        <div className='py-2 flex justify-between items-center'>
            <p className='flex items-center gap-2'>
                <span># {payment.id}</span>
                <span className='bg-amber-500 text-white px-3 rounded-full uppercase text-sm'>{payment.method}</span>
            </p>
            <div className='flex justify-end items-center'>
                <span className='text-zinc-400 text-sm'>{getTimeAgo(new Date(payment.created_at))}</span>
                <p className='w-20 text-end'>₹ {payment.amount}</p>
            </div>
        </div>
    )
}

export const RecentTransactionsCard = ({payments}) => {
  return (
    <div className='px-8 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800'>
        <p className="text-lg font-bold text-gray-700 dark:text-white mb-6">Recent Transactions</p>
        <div>
            {payments.map(payment => <CardPayment key={payment.id} payment={payment} />)}
        </div>
    </div>
  )
}
