import { createContext, useContext, useEffect, useState } from "react";
import settingServer from "../lib/server/setting";
import { UserContext } from "./UserContext";


export const SettingContext = createContext(null)


export const SettingProvider = ({children}) => {

    const [settings, setSettings] = useState(null)
    const {user} = useContext(UserContext)

    const fetchSettings = async () => {
        try{
            const response = await settingServer.getAll()
            const result = {}
            for(let i in response){
                result[i] = response[i].setting_value
            }
            setSettings(result)
        }catch(e){}
    }

    useEffect(() => {
        fetchSettings()
    }, [user])

    return (
        <SettingContext.Provider value={{settings, fetchSettings}}>
            {children}
        </SettingContext.Provider>
    )

}