import React from 'react'
import { NavLink } from 'react-router-dom'
import { DateInput } from '../../inputs/DateInput'

export const OrdersTableHeader = ({count, dates, setDates, onFilterClear, onDelete, deleteDisable}) => {
  return (
    <div>
        <>
        <div className="sm:flex sm:items-center sm:justify-between">
            <div>
                <div className="flex items-center gap-x-3">
                    <h2 className="text-lg font-medium text-gray-800 dark:text-white">Orders</h2>

                    <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">{count} total</span>
                </div>

                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">Complete list of all orders</p>
            </div>

            <div className="flex items-center mt-4 gap-x-3">

                <NavLink to='/orders/create' className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <span>Create order</span>
                </NavLink>
            </div>
        </div>

        <div className="mt-6 md:flex md:items-center md:justify-between">
            {/* <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg dark:bg-gray-900 rtl:flex-row-reverse dark:border-gray-700 dark:divide-gray-700 flex-shrink-0">
                <button onClick={() => onStatusChange(null)} className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${status === null ? 'bg-gray-100' : ''} sm:text-sm dark:bg-gray-800 dark:text-gray-300 hover:bg-gray-100`}>
                    View all
                </button>

                <button onClick={() => onStatusChange(0)} className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm dark:hover:bg-gray-800 ${status === 0 ? 'bg-gray-100' : ''} dark:text-gray-300 hover:bg-gray-100`}>
                    Open
                </button>

                <button onClick={() => onStatusChange(1)} className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${status === 1 ? 'bg-gray-100' : ''} sm:text-sm dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100`}>
                    Closed
                </button>
            </div> */}

            <div className='flex flex-col sm:flex-row items-end gap-4 w-full'>
                <DateInput className='w-full' label={'Date from'} value={dates[0]} setValue={(v) => setDates([v, dates[1]])} max={dates[1]} />
                <DateInput className='w-full' label={'Date to'} value={dates[1]} setValue={(v) => setDates([dates[0], v])} min={dates[0]} />
                <button onClick={onFilterClear} className="px-6 py-2.5 leading-5 text-gray-600 transition-colors duration-300 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100 flex items-center gap-2 border border-zinc-200">
                    Clear
                </button>
                <button disabled={deleteDisable} onClick={onDelete} className="px-6 py-2.5 leading-5 text-white bg-red-600 transition-colors duration-300 rounded-md dark:hover:bg-red-800 dark:text-gray-300 hover:bg-red-500 flex items-center gap-2 border border-zinc-200 disabled:bg-red-300 disabled:text-gray-300">
                    Delete
                </button>
            </div>
        </div>
    </>
    </div>
  )
}
