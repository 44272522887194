import React, { useEffect, useId } from 'react'

export const FileInput = ({label, onChange, acecpt, multiple = false, className, inputRef, errorMsg}) => {

    const id = useId()

    const onFileChangeHandler = (e) => {
        onChange(e.target.files)
    }

    return (
        <div className={className}>
            {label ? <label htmlFor={id} className="block text-sm text-gray-500 dark:text-gray-300 mb-2">{label}</label> : null}
            <input ref={inputRef} multiple={multiple} accept={acecpt} onChange={onFileChangeHandler} id={id} type="file" className="block w-full px-3 py-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-4 file:py-1 file:border-none file:rounded-full dark:file:bg-gray-800 dark:file:text-gray-200 dark:text-gray-300 placeholder-gray-400/70 dark:placeholder-gray-500 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:focus:border-blue-300" />
            {errorMsg ? <p className="text-sm text-red-400">{errorMsg}</p> : null}
        </div>
    )
}
