import { getJSON, postJSON } from "."

const dashboardServer = {
    async getData(){
        const response = await getJSON('/dashboard');
        return response
    },


    async search(term){
        const response = await postJSON('/dashboard/search', {term});
        return response
    }
}

export default dashboardServer