import React from 'react'
import { TableForm } from '../../../components/pages/tables/TableForm'

export const AddTable = () => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Add New Table</h2>
        <TableForm />
    </div>
  )
}
