import React, { useEffect, useState } from 'react'
import { TextInput } from '../../inputs/TextInput'
import { NavLink } from 'react-router-dom'
import { SelectInput } from '../../inputs/SelectInput'
import { ImageInput } from '../../inputs/ImageInput'
import { z } from 'zod'
import { Alert } from '../../box/Alert'
import waiterServer from '../../../lib/server/waiter'

const genders = [
    {
        label: 'Male', value: 'male'
    },
    {
        label: 'Female', value: 'female'
    },
    {
        label: 'others', value: 'others'
    }
]

let removeImage = () => null

const waiterSchema = z.object({
    name: z.string().min(3).max(160),
    sex: z.enum(genders.map(i => i.value))
})

export const WaiterForm = ({waiter}) => {


  const [name, setName] = useState('')
  const [sex, setSex] = useState('male')
  const [image, setImage] = useState(null)

  const [errors, setErrors] = useState({
    name: [],
    sex: [],
    image: []
  })

  const [alertMessage, setAlertMessage] = useState({message: null, type: null})


  useEffect(() => {
    if(waiter){
      setName(waiter.name)
      setSex(waiter.sex)
      setImage(waiter.image)
    }
  }, [waiter]) 

  const imageChanegHandler = (files, cb) => {
    removeImage = cb
    if(files[0]){
        setImage(files[0])
    }else{
        setImage(null)
    }
  }


const formSubmitHandler = async (e) => {
    setErrors({
        name: [],
        sex: [],
        image: []
    })
    e.preventDefault()
    const result = waiterSchema.safeParse({name, sex})
    if(!result.success){
        setErrors(() => {
          return {
            ...errors,
            ...result.error.flatten().fieldErrors
          }
        })
        return
    }

    if(image &&  typeof image !== 'string'){
        if(image.type !== 'image/png' && image.type !== 'image/jpeg'){
          setErrors(() => {
            return {
              ...errors,
              image: ['Please upload png or jpeg image file.']
            }
          })
          return
        }else if(image.size > (2*1024*1024)){
          setErrors(() => {
            return {
              ...errors,
              image: ['Image size must not be more then 2MB.']
            }
          })
          return
        }
      }

      try{
        if(!waiter){
          await waiterServer.create({name, sex, image})
          setAlertMessage({message: 'Item added successfully!', type: 'success'})
          removeImage()
          setName('')
          setSex('male')
        }else{
          await waiterServer.update(waiter.id, {name, sex, image})
          setAlertMessage({message: 'Item updated successfully!', type: 'success'})
        }
        
      }catch(e){
        console.log(e)
        setAlertMessage({message: 'Unable save waiter, Please try again!', type: 'error'})
      }

}


  return (
    <form onSubmit={formSubmitHandler}>
        <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({message: null, type: null})} className='mb-6' />
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
                <TextInput errorMsg={errors.name[0]} label='Name' placeholder={'Full name'} value={name} setValue={setName} className='mb-6' />
                <SelectInput errorMsg={errors.sex[0]} value={sex} setValue={setSex} label='Sex' options={genders} />
            </div>

            <div>
                <ImageInput defaultImage={waiter?.image ? waiterServer.image(waiter.id) : null} errorMsg={errors.image[0]} className='mb-6' onChange={imageChanegHandler} />
            </div>

        </div>

        <div className="mt-6 flex gap-6">
            <button className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
            <NavLink to='/management/waiters' className="px-8 py-2.5 leading-5 text-gray-600 transition-colors duration-300 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100 flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
              </svg>
              Back
            </NavLink>
        </div>
    </form>
  )
}
