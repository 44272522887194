import React, { useContext, useEffect, useState } from 'react'
import { OrdersTable } from '../../components/pages/orders/OrdersTable'
import { OrdersTableHeader } from '../../components/pages/orders/OrdersTableHeader'
import { ToastContext } from '../../contexts/ToastContext'
import orderServer from '../../lib/server/order'
import { SimplePagination } from '../../components/footers/SimplePagination'
import { Modal } from '../../components/box/Modal'
import { TextInput } from '../../components/inputs/TextInput'

export const Orders = () => {

  const [ordersData, setOrdersData] = useState([])
  const [paginateData, setPaginateData] = useState({})
  const {toastError, toastSuccess} = useContext(ToastContext)

  const [deleteId, setDeleteId] = useState(null)
  const [authCode, setAuthCode] = useState('')

  const [selectedOrders, setSelectedOrders] = useState([])

  // const [status, setStatus] = useState(null)

  const [dateRange, setDateRange] = useState(['', ''])

  const fetchOrders = async (page = 1, d = dateRange) => {
    try{
      const response = await orderServer.browse(page, d)
      // console.log(response)
      setOrdersData(response.data)
      const pData = {
        currentPage: response.current_page,
        nextPage: response.next_page_url ? response.current_page + 1 : null,
        prevPage: response.prev_page_url ? response.current_page - 1: null,
        totalPages: response.last_page,
        totalItems: response.total
      }
      setPaginateData(pData)
    }catch(e){
      console.log(e)
      toastError('Unable to fetch orders!')
    }
  }

  useEffect(() => {
    (async () => await fetchOrders())()
  },[])

  const pageChangeHandler = async (direction) => {
    if(direction === 'next' && paginateData.nextPage !== null){
      await fetchOrders(paginateData.nextPage)
    }else if(direction === 'prev' && paginateData.prevPage !== null){
      await fetchOrders(paginateData.prevPage)
    }
    
  }

  const selectOrder = (id) => {
    const index = selectedOrders.indexOf(id);
    if(index === -1){
      setSelectedOrders([...selectedOrders, id])
    }else{
      setSelectedOrders(() => {
        const newArr = [...selectedOrders]
        newArr.splice(index, 1)
        return newArr
      })
    }
  }

  const selectAll = () => {
      if(selectedOrders.length === ordersData.length){
        setSelectedOrders([])
      }else{
        const selected = []
        for(let i of ordersData){
          selected.push(i.id)
        }
        setSelectedOrders(selected)
      }
  }

  // const statusChangeHanlder = async (s) => {
  //   setStatus(s)
  //   await fetchOrders(paginateData.currentPage, s)
  // }

  const dateChangeHandler = async (dates) => {
    setDateRange(dates)
    if(dates[0] && dates[1]){
      await fetchOrders(paginateData.currentPage, dates)
    }    
  }

  const filterClearHandler = async () => {
    setDateRange(['', ''])
    await fetchOrders(paginateData.currentPage, ['', ''])
  }

  const onDeleteHandler = async (id) => {
    setDeleteId(id)
  }

  const onConfirmDelete = async () => {
    try{
      if(deleteId === 'all'){
        await orderServer.deleteAll(selectedOrders, authCode)
      }else{
        await orderServer.delete(deleteId, authCode)
      }      
      toastSuccess('Order deleted successfully!');
      onDeleteCancel()
      await fetchOrders(paginateData.currentPage)
    }catch(e){
      console.log(e.response?.data)
      toastError('Unable to delete order!');
      onDeleteCancel()
    }
    
  }

  const onDeleteCancel = () => {
    setDeleteId(null)
    setAuthCode('')
  }


  return (
    <section className="container p-6 mx-auto">
      <OrdersTableHeader count={paginateData.totalItems} dates={dateRange} setDates={dateChangeHandler} onFilterClear={filterClearHandler} onDelete={() => onDeleteHandler('all')} deleteDisable={selectedOrders.length <= 0} />
      <OrdersTable selectAll={selectAll} selectedOrders={selectedOrders} selectOrder={selectOrder} items={ordersData} onDelete={onDeleteHandler} />
      <SimplePagination currentPage={paginateData.currentPage} totalPages={paginateData.totalPages} pageChange={pageChangeHandler} />
      {deleteId ? 
      <Modal onClose={onDeleteCancel} className='bg-white w-full max-w-xs p-6 rounded-md flex flex-col gap-4'>
        <p className='text-xl'>Are you sure to delete the order id <span className='font-bold'>{deleteId}</span> ?</p>
        <TextInput type='number' value={authCode} setValue={setAuthCode} placeholder={'000000'} label={'Auth Code'} />
        <div className='flex items-center gap-2'>
          <button disabled={authCode.length !== 6} onClick={onConfirmDelete} className='px-6 py-2 block w-full flex-1 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-lg hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80 disabled:bg-red-300'>
              Delete
          </button>
          <button onClick={onDeleteCancel} className='w-full block px-4 flex-1 py-2 mt-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:mt-0 sm:w-auto sm:mx-2 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40'>
              Cancel
          </button>
        </div>
      </Modal> : null}
    </section>
  )
}
