import React, { useContext, useState } from 'react'
import { TextInput } from '../../components/inputs/TextInput'
import { ToastContext } from '../../contexts/ToastContext'
import authServer from '../../lib/server/auth'
import { useNavigate } from 'react-router-dom'

export const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const {toastError} = useContext(ToastContext)
    const navigate = useNavigate()

    const onLoginHandler = async (e) => {
        e.preventDefault()
        try{
            await authServer.login({email, password})
            navigate('/')
        }catch(e){
            console.log(e)
            toastError('Invalid email or password! Please try again.')
        }
    }

  return (
    <div className='w-screen h-screen flex items-center justify-center bg-zinc-200'>
        <div className='w-full max-w-sm px-8 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800'>
           <form onSubmit={onLoginHandler} className='flex flex-col gap-4'>
            <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Login</h2>
                <TextInput type='email' value={email} setValue={setEmail} placeholder={'Email'} />
                <TextInput type='password' value={password} setValue={setPassword} placeholder='Password' />
                <div>
                    <button type='submit' className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Login</button>
                </div>
           </form>
        </div>
    </div>
  )
}
