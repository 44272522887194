import React, { useEffect, useState } from 'react'
import { ReportsFilter } from '../../components/pages/reports/ReportsFilter'
import orderServer from '../../lib/server/order'
import { ReportResult } from './ReportResult'

export const Reports = () => {

  const [result, setResult] = useState(null)

  useEffect(() => {
    onFilter()
  }, [])

  const onFilter = async (startDate, endDate) => {
      try{
        const response = await orderServer.reports({startDate, endDate})
        console.log(response)
        setResult(response)
      }catch(e){
        console.log(e)
      }
  }

  return (
    <section className="container p-6 mx-auto">
      <ReportsFilter onFilter={onFilter} />
      {result ? <ReportResult result={result} /> : null}
    </section>
  )
}
