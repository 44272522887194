import React, { useContext, useEffect, useState } from 'react'
import { Navbar } from '../components/headers/Navbar'
import { Outlet, useNavigate } from 'react-router-dom'
import { ToastContext } from '../contexts/ToastContext'
import authServer from '../lib/server/auth'
import { UserContext } from '../contexts/UserContext'

export const Layout = () => {

  const [loaded, setLoaded] = useState(false)
  const {toastError} = useContext(ToastContext)
  const {setUser} = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try{
        const response = await authServer.verify()
        setUser(response)
        setLoaded(true)
      }catch(e){
        toastError('Please login to continue!')
        navigate('/login')
      }
    })()
  }, [])

  return (
    <div className='flex flex-col h-screen'>
      {loaded ? 
      <>
        <Navbar />
        <Outlet /> 
      </> 
      : null}
    </div>
  )
}
