import { deleteJSON, getJSON, patchJSON, postJSON } from "."


const tableServer = {
    async create(data){
        const response = await postJSON('/tables', data);
        return response.data
    },

    async all(status = 1){
        const response = await getJSON(`/tables?status=${status}`);
        return response
    },

    async delete(id){
        const response = await deleteJSON(`/tables/${id}`)
        return response
    },

    async get(id){
        const response = await getJSON(`/tables/${id}`)
        return response
    },

    async update(id, data){
        const response = await patchJSON(`/tables/${id}`, data)
        return response
    },

    async restore(id){
        const response = await patchJSON(`/tables/${id}/restore`)
        return response
    }
}

export default tableServer