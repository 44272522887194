import React from 'react'
import { SearchInput } from '../../inputs/SearchInput'
import { OrderGridItem } from './OrderGridItem'

export const OrderItemsList = ({items, searchTerm, onSearch, addItem}) => {
    

  return (
    <div className='col-span-1 lg:col-span-2 xl:col-span-3 h-full min-h-[60vh] overflow-y-auto'>
        <div className='flex flex-col lg:flex-row justify-between items-center w-full mb-6'>
            <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Select Item</h2>
            <SearchInput searchTerm={searchTerm} onSearch={onSearch} />
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
            {items.map(item => <OrderGridItem item={item} key={item.id} addItem={() => addItem(item)} />)}
        </div>
    </div>
  )
}
