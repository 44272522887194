import React from 'react'
import itemServer from '../../lib/server/item'
import { NavLink } from 'react-router-dom'

const ResultItem = ({item}) => {
    return (
        <div className='flex gap-3 items-center'>
            <img src={itemServer.image(item.id)} className='w-12 h-12 rounded-md object-cover object-center' alt="" />
            <NavLink to={`/management/items/${item.id}`}>{item.title}</NavLink>
        </div>
    )
}

export const ItemsSearchResult = ({items}) => {
  return (
    <div className='mb-10'>
        <p className='text-lg font-medium border-b border-zinc-300 mb-6'>Items</p>
        <div className='flex flex-col gap-3'>
            {items.map(item => <ResultItem key={item.id} item={item} />)}
        </div>
    </div>
  )
}
