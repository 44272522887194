import React, { useEffect, useState } from 'react'
import { Alert } from '../../box/Alert'
import { TextInput } from '../../inputs/TextInput'
import { NavLink } from 'react-router-dom'
import { z } from "zod";
import tableServer from '../../../lib/server/table';

const tableSchema = z.object({
    tableCode: z.string().min(1),
    seats: z.number().gt(0)
})

export const TableForm = ({table}) => {

    const [alertMessage, setAlertMessage] = useState({message: null, type: null})

    const [tableCode, setTableCode] = useState('')
    const [seats, setSeats] = useState('')

    const [errors, setErrors] = useState({
        tableCode: [],
        seats: [],
    })

    useEffect(() => {
        if(table){
            setTableCode(table.table_code)
            setSeats(table.seats)
        }
    }, [table])

    const onSubmitHandler = async(e) => {
        e.preventDefault()
        
        const result = tableSchema.safeParse({tableCode, seats: parseInt(seats)})
        // console.log(result.error.flatten().fieldErrors)
        if(!result.success){
            setErrors(() => {
            return {
                ...errors,
                ...result.error.flatten().fieldErrors
            }
            })
            return
        }

        try{
            if(!table){
                await tableServer.create({table_code: tableCode, seats: parseInt(seats)})
                setAlertMessage({message: 'Table added successfully!', type: 'success'})
                setTableCode('')
                setSeats(1)
            }else{
                await tableServer.update(table.id, {table_code: tableCode, seats: parseInt(seats)})
                setAlertMessage({message: 'Table updated successfully!', type: 'success'})
            }
        }catch(e){
            console.log(e)
            setAlertMessage({message: 'Unable to create table, Please try again!', type: 'error'})
        }
    }


    return (
        <form onSubmit={onSubmitHandler}>
            <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({message: null, type: null})} className='mb-6' />
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                <TextInput errorMsg={errors.tableCode[0]} value={tableCode} setValue={setTableCode} label={'Table No.'} placeholder={'Ex. A1 ... Z26'} />
                <TextInput errorMsg={errors.seats[0]} type='number' value={seats} setValue={(val) => setSeats(val)} label={'No. of seats'} placeholder={'Ex. 1, 2, 3 ...'} />
            </div>
            <div className="mt-6 flex gap-6">
                <button className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                <NavLink to='/management/tables' className="px-8 py-2.5 leading-5 text-gray-600 transition-colors duration-300 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100 flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                    </svg>
                    Back
                </NavLink>
            </div>
        </form>
    )
}
