import React, { useEffect, useState } from 'react'

export const TablePegination = ({total, itemsPerPage, nextPage, prevPage}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        setTotalPages(Math.ceil(total/itemsPerPage))
    }, [total, itemsPerPage])

    const nextPageHandler = () => {
        if((currentPage + 1) <= totalPages){
            setCurrentPage(prev => prev + 1)
            nextPage(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
        }
    }

    const prevPageHandler = () => {
        if(currentPage > 1){
            setCurrentPage(prev => prev - 1)
            prevPage((currentPage - 2) * itemsPerPage, (currentPage - 1) * itemsPerPage)
        }
    }

  return (
    <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
        <div className="text-sm text-gray-500 dark:text-gray-400">
            Page <span className="font-medium text-gray-700 dark:text-gray-100">{currentPage} of {totalPages}</span> 
        </div>

        <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
            
            <button onClick={prevPageHandler} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>

                <span>
                    previous
                </span>
            </button>

            <button onClick={nextPageHandler} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                <span>
                    Next
                </span>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
            </button>
        </div>
    </div>
  )
}
