const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


export function readableDateWithWeek(date) {    
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const dayOfWeek = daysOfWeek[date.getDay()];
  
    let suffix;
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    } else {
      suffix = "th";
    }
  
    return `${dayOfWeek} ${day}${suffix} ${months[month]}, ${year}`;
  }

  export function getTimeAgo(date) {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60
    };

    for (let interval in intervals) {
        const value = Math.floor(diffInSeconds / intervals[interval]);
        if (value >= 1) {
            return value === 1 ? `${value} ${interval}` : `${value} ${interval}s`;
        }
    }

    return "Just now";
}