import React, { useEffect, useRef, useState } from 'react'
import { TextInput } from '../../inputs/TextInput'
import { CheckBox } from '../../inputs/CheckBox'
import { FileInput } from '../../inputs/FileInput'
import { z } from "zod";
import itemServer from '../../../lib/server/item';
import { Alert } from '../../box/Alert';
import { NavLink } from 'react-router-dom';

const itemSchema = z.object({
  title: z.string().min(3).max(160),
  description: z.string().max(5000).nullish(),
  price: z.number().positive()
})

export const ItemForm = ({item}) => {

  const imageView = useRef(null)
  const imageInputRef = useRef(null)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [useQuantity, setUseQuantity] = useState(false)
  const [image, setImage] = useState(null)
  const [nonVeg, setNonVeg] = useState(false)
  const [price, setPrice] = useState('')

  const [alertMessage, setAlertMessage] = useState({message: null, type: null})

  const [errors, setErrors] = useState({
    title: [],
    description: [],
    image: [],
    price: []
  })

  useEffect(() => {
    if(item){
      setTitle(item.title)
      setDescription(item.description ? item.description : '')
      setUseQuantity(item.use_quantity === 1)
      setNonVeg(item.non_veg === 1)
      setPrice(item.price)
      setImage(item.image)
    }    
  }, [item])


  const setImageHandler = (files) => {
    if(files[0]){
      setImage(files[0])
    }else{
      setImage(null)
    }    
  }

  const removeImageHandler = () => {
    setImage(null)
    imageInputRef.current.value = '';
  }


  useEffect(() => {
    if(image){
      // console.log(typeof image)
      if(typeof image === 'string'){
        if (imageView.current) {
          imageView.current.src = itemServer.image(item.id); // Update the src attribute of img tag
        }
      }else{
        const reader = new FileReader();
        reader.onloadend = () => {
          if (imageView.current) {
            imageView.current.src = reader.result; // Update the src attribute of img tag
          }
        };
        reader.readAsDataURL(image);
      }
      
    }else{
      if (imageView.current) {
        imageView.current.src = ''; // Update the src attribute of img tag
      }
    }
  }, [image])


  const onSubmitHandler = async(e) => {
    e.preventDefault()
    const result = itemSchema.safeParse({title, description, price})
    // console.log(result.error.flatten().fieldErrors)
    if(!result.success){
        setErrors(() => {
          return {
            ...errors,
            ...result.error.flatten().fieldErrors
          }
        })
        return
    }

    // console.log(image)
    if(image && typeof image !== 'string'){
      if(image.type !== 'image/png' && image.type !== 'image/jpeg'){
        setErrors(() => {
          return {
            ...errors,
            image: ['Please upload png or jpeg image file.']
          }
        })
        return
      }else if(image.size > (2*1024*1024)){
        setErrors(() => {
          return {
            ...errors,
            image: ['Image size must not be more then 2MB.']
          }
        })
        return
      }
    }
    try{
      if(!item){
        await itemServer.create({title, description, image, non_veg: nonVeg, use_quantity: useQuantity, price})
        setAlertMessage({message: 'Item added successfully!', type: 'success'})
        setTitle('')
        setDescription('')
        setUseQuantity(false)
        setImage(null)
        removeImageHandler()
        setNonVeg(false)
        setPrice(0.00)
      }else{
        const response = await itemServer.update(item.id, {title, description, image, non_veg: nonVeg, use_quantity: useQuantity, price})
        // console.log(response)
        setAlertMessage({message: 'Item update successfully!', type: 'success'})
      }
    }catch(e){
      console.log(e)
      if(!item){
        setAlertMessage({message: 'Unable to create item, Please try again!', type: 'error'})
      }else{
        setAlertMessage({message: 'Unable to update item, Please try again!', type: 'error'})
      }
      
    }
    

  }

  const priceChangeHanlder = (val) => {
    if(!val){
      setPrice('')
    }else{
      setPrice(parseFloat(val))
    }
  }

  return (
    <form onSubmit={onSubmitHandler}>
        <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({message: null, type: null})} className='mb-6' />
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
           <div>
            <TextInput errorMsg={errors.title[0]} value={title} setValue={setTitle} className='mb-6' label='Title' placeholder='Enter item title' />
            <TextInput errorMsg={errors.description[0]} value={description} setValue={setDescription} className='mb-6' label='Description' placeholder='Enter a small description (optional)' type='textarea' />
            <TextInput errorMsg={errors.price[0]} value={price} setValue={priceChangeHanlder} className='mb-6' label='Price' placeholder='0.00' step={0.01} type='number' />
           </div>
           <div>
            <FileInput errorMsg={errors.image[0]} label='Image' onChange={setImageHandler} inputRef={imageInputRef} className='mb-6' />
            <div className={`flex gap-6 items-start  ${!image ? 'hidden' : ''}`}>
              <img src={item ? itemServer.image(item.id) : ''} className={`w-32 rounded-md shadow mb-6`} ref={imageView} alt="" />              
              <button onClick={removeImageHandler} type='button' className="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <CheckBox className='mb-6' label='Set avilable quantity' checked={useQuantity} setChecked={setUseQuantity} />
            <CheckBox checked={nonVeg} setChecked={setNonVeg} className='mb-6' label='Non Veg' />
            
           </div>
        </div>

        <div className="mt-6 flex gap-6">
            <button className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
            <NavLink to='/management/items' className="px-8 py-2.5 leading-5 text-gray-600 transition-colors duration-300 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100 flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
              </svg>
              Back
            </NavLink>
        </div>
      </form>
  )
}
