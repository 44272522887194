import React, { useEffect, useState } from 'react'

export const ReportResult = ({result}) => {

    const [computed, setComputed] = useState({payments: 0, items: 0})

    useEffect(() => {
        const comp = {payments: 0, items: 0}
        for(let i in result){
            const order = result[i]
            comp.payments += parseFloat(order.final)
            comp.items += order.items.length
        }
        setComputed(comp)
    }, [result])

  return (
    <div>
        <div className='grid grid-cols-3 gap-3'>
            <div className='text-left'>
                <p className='text-4xl'>{result.length}</p>
                <p>Total Orders Completed</p>
            </div>
            <div className='text-center'>
                <p className='text-4xl'>₹ {computed.payments}</p>
                <p>Total Payments Collected</p>
            </div>
            <div className='text-right'>
                <p className='text-4xl'>{computed.items}</p>
                <p>Total Items Served</p>
            </div>
        </div>
    </div>
  )
}
