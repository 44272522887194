import React, { useContext, useEffect, useState } from 'react'
import settingServer from '../../lib/server/setting'
import { TextInput } from '../../components/inputs/TextInput'
import { ToastContext } from '../../contexts/ToastContext'
import { SettingContext } from '../../contexts/SettingContext';

function cleanString(input) {
    // Replace multiple spaces with a single space
    let result = input.replace(/\s+/g, ' ');

    // Remove leading and trailing spaces
    result = result.trim();

    // Replace ', ' with ','
    result = result.replace(/, /g, ',');

    return result;
}

export const Settings = () => {

    // const [settingsData, setSettingsData] = useState(null)

    const [paymentMethods, setPaymentMethods] = useState('');
    const [gst, setGst] = useState({sgst: 0, cgst: 0, gst_no: ''})

    const {toastSuccess, toastError} = useContext(ToastContext)
    
    const {fetchSettings, settings} = useContext(SettingContext)

    useEffect(() => {
        if(!settings){
            return
        }
        setPaymentMethods(JSON.parse(settings.PAYMENT_METHODS).join(', '))
        setGst({
            sgst: settings.SGST,
            cgst: settings.CGST,
            gst_no: settings.GST_NO
        })
    }, [settings])


    const saveSettings = async (data) => {
        try{
            await settingServer.save(data)
            toastSuccess('Settings saved successfully!')
            fetchSettings()
        }catch(e){
            toastError('Unable to save settings!')
        }
    }

  return (
    <section className="container p-6 mx-auto">
        {settings ? <>
            <div className='w-full max-w-xl px-8 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800 mx-auto'>
                <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white mb-6">Payments & Tax</h2>
                <div className='flex items-start justify-between mb-6'>
                    <div>
                        <p>Payment Methods:</p>
                        <p className='text-xs text-zinc-500'>Enter a list of payment methods, (comma separated)</p>
                    </div>
                    <TextInput type='textarea' value={paymentMethods} setValue={setPaymentMethods} />
                </div>
                <div className='flex items-center justify-between mb-6'>
                    <p>SGST (%):</p>
                    <TextInput type='number' value={gst.sgst} setValue={(value) => setGst({...gst, sgst: value})}  />
                </div>
                <div className='flex items-center justify-between mb-6'>
                    <p>SGST (%):</p>
                    <TextInput type='number' value={gst.cgst} setValue={(value) => setGst({...gst, cgst: value})}  />
                </div>
                <div className='flex items-center justify-between mb-6'>
                    <p>GST NO:</p>
                    <TextInput type='text' value={gst.gst_no} setValue={(value) => setGst({...gst, gst_no: value})}  />
                </div>
                <div>
                    <button 
                        onClick={() => saveSettings({PAYMENT_METHODS: cleanString(paymentMethods), SGST: gst.sgst, CGST: gst.cgst, GST_NO: gst.gst_no})} 
                        className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                        Save
                    </button>
                </div>
            </div>
        </> : null}
    </section>
  )
}
