import React, { useState } from 'react'
import { DateInput } from '../../inputs/DateInput'

export const ReportsFilter = ({onFilter}) => {

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
    await onFilter(startDate, endDate)
  }

  return (
    <div className='mb-12'>
        <h2 className="text-lg font-medium text-gray-800 dark:text-white mb-6">Reports</h2>

        <form onSubmit={onSubmit} className='flex items-end gap-6'>
            <DateInput label='Start date' value={startDate} setValue={setStartDate} className={'w-full max-w-xs'} />
            <DateInput label='End date (Optional)' value={endDate} setValue={setEndDate} className={'w-full max-w-xs'} />
            <button type='submit' className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Filter</button>
        </form>
    </div>
  )
}
