import React from 'react'
import { NavLink } from 'react-router-dom'

const CardTable = ({table}) => {
    return (
        <div className='flex gap-2 items-center py-2'>
            <div className='w-12 h-12 flex justify-center items-center rounded-full flex-shrink-0 bg-zinc-300 text-zinc-500'>
                <span className='font-bold text-lg'>{table.table_code}</span>
            </div>
            <div className='w-full'>
                <p className='font-medium'>Table #{table.table_code}</p>
            </div>
            <NavLink to={`/management/tables/${table.id}`} className='text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
            </NavLink>
        </div>
    )
}

export const ManagementTableCard = ({tables}) => {
  return (
    <div className="w-full px-8 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800 flex flex-col h-full">
        <div className="flex items-center justify-between h-fit">
            <span className="text-xl font-bold text-gray-700 dark:text-white">Tables</span>
            <NavLink to={'/management/tables/add'} className="px-3 py-1 text-sm font-bold rounded cursor-pointer text-white transition-colors duration-300 transform bg-blue-600 hover:bg-blue-50 focus:ring-blue-300" role="button">Create</NavLink>
        </div>

        <div className='h-full'>
          <div className="py-2 h-full overflow-y-hidden max-h-[36rem]">
            {tables.map(item => <CardTable key={item.id} table={item} />)}
          </div>
        </div>

        <div className="flex items-center justify-end h-fit pt-4">
            <NavLink to={'/management/tables'} className="px-3 py-1 text-sm font-bold text-gray-100 transition-colors duration-300 transform bg-gray-600 rounded cursor-pointer hover:bg-gray-500" role="button">View All</NavLink>
        </div>
    </div>
  )
}
