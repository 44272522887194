import React, { useContext, useEffect, useState } from 'react'
import { OrderItemsList } from '../../components/pages/orders/OrderItemsList'
import itemServer from '../../lib/server/item'
import { OrderView } from '../../components/pages/orders/OrderView'
import orderServer from '../../lib/server/order'
import { ToastContext } from '../../contexts/ToastContext'
import { useNavigate, useParams } from 'react-router-dom'
import { SettingContext } from '../../contexts/SettingContext'
import { Modal } from '../../components/box/Modal'
import { TextInput } from '../../components/inputs/TextInput'

export const OrderCheckout = () => {

    const [itemsData, setItemsData] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    const [selectedItems, setSelectedItems] = useState({})
    const [totalAmount, setTotalAmount] = useState(0)
    const [finalAmount, setFinalAmount] = useState(0)
    const [note, setNote] = useState('')
    const [currentNote, setCurrentNote] = useState('')

    const [addNote, setAddNote] = useState(false)

    const [status, setStatus] = useState(0)

    const [payments, setPayments] = useState([])

    const [discount, setDiscount] = useState('0')

    const {toastSuccess, toastError} = useContext(ToastContext);

    const [enablePrint, setEnablePrint] = useState(false)

    const [orderData, setOrderData] = useState(null)

    // const [modalData, setModalData] = useState(null)

    const {id} = useParams()
    const navigate = useNavigate()

    const {settings} = useContext(SettingContext)


    useEffect(() => {
        (async () => {
          try{
            const response = await itemServer.all(1)
            setItemsData(response)
            setFilteredItems(response)
          }catch(e){
              console.log(e)
          }
        })()
     }, [])

     useEffect(() => {
      if(!id){
        setSelectedItems({})
        setTotalAmount(0)
        setFinalAmount(0)
        setPayments([])
        setOrderData(null)
        setDiscount('0')
        setStatus(0)
        return
      }
      (async () => {
        try{
          const response = await orderServer.get(id)
          // console.log(response)
          if(response.status > 0){
            // setModalData({orderId: response.id, totalItems: response.items.length, totalAmount: response.final, date: response.date})
            setEnablePrint(true)
            // return
          }
          // console.log(response)
          setOrderData(response)
          const newSelectedItems = {}
          for(let i in response.items){
            const _item = response.items[i]
            newSelectedItems[_item.id] = {
              ..._item,
              count: _item.pivot.quantity,
              max: _item.pivot.quantity
            }
            delete newSelectedItems[_item.id].pivot
          }
          setSelectedItems(newSelectedItems)
          setDiscount(response.discount)
          setStatus(response.status)
          setNote(response.note)
          setCurrentNote(response.note ? response.note : '')
        }catch(e){
          console.log(e)
        }
      })()
     }, [id])

     useEffect(() => {

      let total  = 0;
      for(let i in selectedItems){
        total = total + (selectedItems[i].price * selectedItems[i].count)
      }
      setTotalAmount(total)
      if(settings){
        let amount = (((total * parseFloat(settings.CGST))/100) + ((total * parseFloat(settings.SGST)) / 100))
        setFinalAmount(((total + amount) - (discount ? parseInt(discount) : 0)).toFixed(2))
      }      
     }, [selectedItems, settings, discount])

     const addItem = (item) => {
        const newPrev = {...selectedItems}
        if(newPrev[item.id]){
            newPrev[item.id].count =  newPrev[item.id].count+1
        }else{
            newPrev[item.id] = {
                ...item,
                count: 1
            }
        }
        setSelectedItems(newPrev)
     }


     const onSearchHandler = (term) => {
        setSearchTerm(term)
        const result = itemsData.filter(item => item.title.toLowerCase().includes(term.toLowerCase()))
        setFilteredItems(result)
      }

      const quantityChangeHandler = (id, count) => {
        if(!selectedItems[id]){
            return
        }
        const newPrev = {...selectedItems}
        if(count === 0){
            delete newPrev[id];
            
        }else{
            newPrev[id].count = count
        }
        setSelectedItems(newPrev)
        
    }


    const addPaymentHandler = (data) => {
        const newPrev = [...payments]
        newPrev.push(data)
        setPayments(newPrev)
    }

    const removePaymentHandler = (index) => {
      const newPrev = [...payments]
      newPrev.splice(index, 1)
      setPayments(newPrev)
    }

    const onSubmitHandler = async () => {
        const items = []
        for(let i in selectedItems){
          items.push({
            item_id: selectedItems[i].id, price: selectedItems[i].price, quantity: selectedItems[i].count
          })
        }
        if(!items[0]){
          toastError('Please add at least 1 item!')
          return
        }
        
        let totalPaid = 0;
        for(let i in payments){
          totalPaid = totalPaid + parseFloat(payments[i].amount)
        }

        if(id && orderData){
          try{
            await orderServer.addItems(id, {items, discount})
            await orderServer.update(id, {status, payments})
            toastSuccess('Order saved successfully!')
            // setModalData({orderId: orderData.id, totalItems: items.length, totalAmount: response.final, date: orderData.date})
            // setEnablePrint(true)
          }catch(e){
            console.log(e)
            toastError('Unable to save order! Please try again.');
          }
        }else{
          try{
            const response = await orderServer.create({items, payments, status, discount, table: 1, note})
            toastSuccess('Order created successfully!')
            navigate(`/orders/${response.id}`)
            // setModalData({orderId: response.id, totalItems: items.length, totalAmount: response.final, date: response.date})
            // setEnablePrint(true)
          }catch(e){
            console.log(e)
            toastError('Unable to create order! Please try again.');
          }
        }

        
    }

    const goBackHandler = () => {
      navigate('/orders')
    }

    const closeAddNote = () => {
      setCurrentNote(note)
      setAddNote(false)
    }

    const onNoteSave = async () => {
      if(!id){
        setNote(note)
      }else{
        try{
          await orderServer.saveNote(id, currentNote)
          setNote(currentNote)
        }catch(e){
          console.log(e)
        }
      }
      setAddNote(false)
    }

  return (
    <div className="container mx-auto p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 h-full">
      <OrderItemsList items={filteredItems} onSearch={onSearchHandler} searchTerm={searchTerm} addItem={addItem}  />
      <OrderView setAddNote={setAddNote} note={note} status={status} setStatus={setStatus} finalAmount={finalAmount} items={selectedItems} changeQuantity={quantityChangeHandler} totalAmount={totalAmount} addPayment={addPaymentHandler} payments={payments} onPaymentRemove={removePaymentHandler} onSubmit={onSubmitHandler} enablePrint={enablePrint} orderData={orderData} discount={discount} setDiscount={setDiscount} />
      {/* {enablePrint && modalData ? <OrderCompleteModal modalData={modalData} goBack={goBackHandler} /> : null} */}
      {addNote ?
      <Modal className='bg-white rounded-md p-6 w-full max-w-sm' onClose={closeAddNote}>
        <div className='flex flex-col gap-4'>
          <p className='font-medium text-xl'>Add Note</p>
          <TextInput value={currentNote} setValue={setCurrentNote} type='textarea' rows='2' placeholder='Type your order note here ...' />
          <div className='flex gap-2'>
            <button onClick={onNoteSave} type='button' className="px-6 py-2 w-full font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 disabled:bg-blue-300">
                  Save
            </button>
            <button onClick={closeAddNote} type='button' className='px-8 py-2.5 leading-5 text-center text-gray-600 transition-colors duration-300 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100 flex items-center justify-center gap-2 w-full'>Cancel</button>
          </div>
        </div>
      </Modal> : null}
    </div>
  )
}
