import React, { useEffect, useRef, useState } from 'react'
import { FileInput } from './FileInput'

export const ImageInput = ({errorMsg, onChange, className, defaultImage}) => {
    
    const imageView = useRef(null)
    const inpuRef = useRef(null)
    const [hasImage, setHasImage] = useState(false)

    useEffect(() => {
        if(defaultImage){
            setHasImage(true)
            if(imageView.current){
                imageView.current.src = defaultImage
            }
            
        }else{
            setHasImage(false)
            if(imageView.current){
                imageView.current.src = ''
            }
        }
    }, [defaultImage, imageView.current])

    const onFileChangeHandler = (files) => {
        onChange(files, removeImageHandler)
        if(files[0]){
            setHasImage(true)
            const reader = new FileReader();
            reader.onloadend = () => {
            if (imageView.current) {
                imageView.current.src = reader.result; // Update the src attribute of img tag
            }
            };
            reader.readAsDataURL(files[0]);
        }else{
            setHasImage(false)
            if (imageView.current) {
                imageView.current.src = ''; // Update the src attribute of img tag
            }
        }
    }
    const removeImageHandler =() => {
        setHasImage(false)
        imageView.current.src = '';
        inpuRef.current.value = '' 
        onChange([], removeImageHandler)     
    }

  return (
    <>
        <FileInput className={className} inputRef={inpuRef} errorMsg={errorMsg} label='Image' onChange={onFileChangeHandler} acecpt='image/png, image/jpeg' />
        <div className={`flex gap-6 items-start  ${!hasImage ? 'hidden' : ''}`}>
            <img src="" className={`w-32 rounded-md shadow mb-6`} ref={imageView} alt="" />              
                <button onClick={removeImageHandler} type='button' className="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    </>
  )
}
