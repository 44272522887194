import React, { useContext, useEffect, useState } from 'react'
import { TableForm } from '../../../components/pages/tables/TableForm'
import { ToastContext } from '../../../contexts/ToastContext'
import tableServer from '../../../lib/server/table'
import { useParams } from 'react-router-dom'

export const UpdateTable = () => {


    const [table, setTable] = useState(null)

    const {toastError} = useContext(ToastContext)
    const {id} = useParams()

    useEffect(() => {
        (async () => {
            try{
                const response = await tableServer.get(id)
                setTable(response)
            }catch(e){
                console.log(e)
                toastError('Unable to get table! Please refresh the page.')
            }
        })()
    }, [])

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Update Table</h2>
        <TableForm table={table} />
    </div>
  )
}
