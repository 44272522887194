import React from 'react'
import { Layout } from './pages/Layout';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import { Dashboard } from './pages/Dashboard';
import { Items } from './pages/management/items/Items';
import { Management } from './pages/management/Management';
import { AddItem } from './pages/management/items/AddItem';
import { Waiters } from './pages/management/waiters/Waiters';
import { AddWaiter } from './pages/management/waiters/AddWaiter';
import { Tables } from './pages/management/tables/Tables';
import { AddTable } from './pages/management/tables/AddTable';
import { Orders } from './pages/orders/Orders';
import { OrderCheckout } from './pages/orders/OrderCheckout';
import { ToastProvider } from './contexts/ToastContext';
import { UpdateItem } from './pages/management/items/UpdateItem';
import { UpdateWaiter } from './pages/management/waiters/UpdateWaiter';
import { UpdateTable } from './pages/management/tables/UpdateTable';
import { Reports } from './pages/reports/Reports';
import { Search } from './pages/Search';
import { SearchProvider } from './contexts/SearchContext';
import { Login } from './pages/auth/Login';
import { UserProvider } from './contexts/UserContext';
import { Profile } from './pages/users/Profile';
import { OrderPrint } from './pages/orders/OrderPrint';
import { Settings } from './pages/settings/Settings';
import { SettingProvider } from './contexts/SettingContext';

const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <ToastProvider>
          <SearchProvider>
            <SettingProvider>
              <Routes>

                <Route path='/login' element={<Login />} />
                <Route path='/' element={<Layout />}>

                  <Route index element={<Dashboard />} />

                  <Route path='management'>
                    <Route index element={<Management />} />

                    <Route path='items' element={<Items />} />
                    <Route path='items/add' element={<AddItem />} />
                    <Route path='items/:id' element={<UpdateItem />} />

                    <Route path='waiters' element={<Waiters />} />
                    <Route path='waiters/add' element={<AddWaiter />} />
                    <Route path='waiters/:id' element={<UpdateWaiter />} />

                    <Route path='tables' element={<Tables />} />
                    <Route path='tables/add' element={<AddTable />} />
                    <Route path='tables/:id' element={<UpdateTable />} />

                  </Route>

                  <Route path='orders' element={<Orders />} />
                  <Route path='orders/create' element={<OrderCheckout />} />
                  <Route path='orders/:id' element={<OrderCheckout />} />

                  <Route path='reports' element={<Reports />} />

                  <Route path='search' element={<Search />} />

                  <Route path='profile' element={<Profile />} />

                  <Route path='settings' element={<Settings />} />

                </Route>
                <Route path='/orders/print/kitchen/:id' element={<OrderPrint price={false} />} />
                <Route path='/orders/print/:id' element={<OrderPrint />} />
              </Routes>
            </SettingProvider>
          </SearchProvider>
        </ToastProvider>
      </UserProvider>
    </BrowserRouter>
  )
}
 export default App;