import { baseUrl, deleteJSON, getJSON, patchJSON, postMultipart } from "."

const itemServer =  {

    async create(data){
        const response = await postMultipart('/items', data);
        return response.data
    },

    async all(status){
        const response = await getJSON(`/items?status=${status}`);
        return response
    },

    image(id){
        return `${baseUrl}/items/image/${id}`;
    },

    async delete(id){
        const response = await deleteJSON(`/items/${id}`)
        return response
    },

    async get(id){
        const response = await getJSON(`/items/${id}`);
        console.log(response)
        return response
    },

    async update(id, data){
        const response = await postMultipart(`/items/${id}`, data)
        return response
    },

    async restore(id){
        const response = await patchJSON(`/items/${id}/restore`)
        return response
    }

}

export default itemServer