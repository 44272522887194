import { getJSON, postJSON } from "."

const authServer = {

    async login(data){
        const response = await postJSON('/auth', data)
        localStorage.setItem('_auth', JSON.stringify(response))
        return response
    },

    getLocal(){
        const jsonData = localStorage.getItem('_auth')
        return JSON.parse(jsonData)
    },

    async verify(){
        const response = await getJSON('/users/profile');
        return response
    }

}

export default authServer