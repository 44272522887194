import React, { useContext, useEffect, useState } from 'react'
import { TextInput } from '../../inputs/TextInput'
import { z } from 'zod'
import { ToastContext } from '../../../contexts/ToastContext'
import userServer from '../../../lib/server/user'

const userSchema = z.object({
    name: z.string().min(2).max(160),
    email: z.string().email()
})

export const UserForm = ({user, setUser}) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')

    const {toastSuccess, toastError} = useContext(ToastContext)

    const [errors, setErrors] = useState({
        name: [],
        email: [],
        password: [],
        cPassword: []
    })

    useEffect(() => {
        if(user){
            setName(user.name)
            setEmail(user.email)
        }
    }, [user])


    const onSaveHandler = async () => {
        setErrors({
            name: [],
            email: [],
            password: [],
            cPassword: []
        })
        const result = userSchema.safeParse({name, email})
        if(!result.success){
            setErrors(() => {
            return {
                ...errors,
                ...result.error.flatten().fieldErrors
            }
            })
            return
        }
        if(password){
            if(password.length < 8){
                setErrors(() => {
                    return {
                        ...errors,
                        password: ['Password is too short.']
                    }
                })
                return
            }
            if(password !== cPassword){
                setErrors(() => {
                    return {
                        ...errors,
                        cPassword: ['Password mismatch.']
                    }
                })
                return
            }
        }

        try{
            const data = {name, email}
            if(password !== ''){
                data.password = password
            }
            const response = await userServer.updateProfile(data)
            setUser(response)
            toastSuccess('Profile saved successfully!')
            setPassword('')
            setCPassword('')
        }catch(e){
            console.log(e)
            toastError('Unable to save profile. Please try again.')
        }

    }

  return (
    <div>
         <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2 mb-6">
            <TextInput label={'Name'} value={name} setValue={setName} errorMsg={errors.name[0]} />
            <TextInput label={'Email'} type='email' value={email} setValue={setEmail} errorMsg={errors.email[0]}  />
            <TextInput label={'Password'} type='password' value={password} setValue={setPassword} placeholder={'Leave in blank'} errorMsg={errors.password[0]} />
            <TextInput label={'Retype Password'} type='password' value={cPassword} setValue={setCPassword} placeholder={'Leave in blank'} errorMsg={errors.cPassword[0]} />
         </div>
         <div>
            <button onClick={onSaveHandler} className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
         </div>
    </div>
  )
}
