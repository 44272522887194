import React from 'react'

export const DateInput = ({className, label, value, setValue, disabled, errorMsg = null, min, max}) => {

    const onChangeHandler = (e) => {
        setValue(e.target.value)
    }
  return (
    <div className={className}>
        {label ? <label className="text-gray-700 dark:text-gray-200 mb-2">{label}</label> : null}

        <input min={min} max={max} type="date" value={value} onChange={onChangeHandler} disabled={disabled} className={`block mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300`} />

        {errorMsg ? <p className="text-sm text-red-400">{errorMsg}</p> : null}
    </div>
  )
}
