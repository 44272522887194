import React, { useEffect, useState } from 'react'
import { WaiterForm } from '../../../components/pages/waiters/WaiterForm'
import waiterServer from '../../../lib/server/waiter'
import { useParams } from 'react-router-dom'

export const UpdateWaiter = () => {

    const [waiter, setWaiter] = useState(null)
    const {id} = useParams()

    useEffect(() => {
        (async () => {
            try{
                const response = await waiterServer.get(id)
                // console.log(response)
                setWaiter(response)
            }catch(e){
                console.log(e)
            }
        })()
    }, [])

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Update waiter</h2>
      {waiter ? <WaiterForm waiter={waiter} /> : null}
    </div>
  )
}
