import { getJSON, patchJSON } from "."

const userServer = {

    async profile(){
        const response = await getJSON('/users/profile')
        return response
    },

    async updateProfile(data){
        const response = await patchJSON('/users/profile', data)
        return response
    }

}

export default userServer