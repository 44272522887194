import React, { useEffect, useState } from 'react'
import { ManagementItemsCard } from '../../components/pages/management/ManagementItemsCard'
import managementServer from '../../lib/server/management'
import { ManagementWaitersCard } from '../../components/pages/management/ManagementWaitersCard'
import { ManagementTableCard } from '../../components/pages/management/ManagementTableCard'

export const Management = () => {

  const [items, setItems] = useState([])
  const [waiters, setWaiters] = useState([])
  const [tables, setTables] = useState([])

  useEffect(() => {
    (async () => {
      const response = await managementServer.fetchData()
      // console.log(response)
      setItems(response.items)
      setWaiters(response.waiters)
      setTables(response.tables)
    })()
  }, [])

  return (
    <div className='container mx-auto p-6 grid grid-cols-1 lg:grid-cols-3 gap-12'>
        <ManagementItemsCard items={items} />
        <ManagementWaitersCard waiters={waiters} />
        <ManagementTableCard tables={tables} />
    </div>
  )
}
