import React, { useContext, useEffect } from 'react'
import { UserForm } from '../../components/pages/users/UserForm'
import { UserContext } from '../../contexts/UserContext'


export const Profile = () => {

    const {user, setUser} = useContext(UserContext)



  return (
    <section className="container p-6 mx-auto">
        <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Update Profile</h2>
        <UserForm user={user} setUser={setUser} />
    </section>
  )
}
