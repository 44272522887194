import React, { useEffect } from 'react'

export const Alert = ({message, type, onClose, className}) => {

    useEffect(() => {
        const handler = setTimeout(() => {
            onClose()
        }, 5000)
        return () => clearTimeout(handler)
    }, [message])

  return (
    <>
        {message ? 
        <div className={`w-full rounded-md text-white ${type === 'error' ? 'bg-red-500' : ''} ${type === 'success' ? 'bg-emerald-500' : ''} ${className}`}>
            <div className="container flex items-center justify-between px-6 py-4 mx-auto">
                <div className="flex">
                    <svg viewBox="0 0 40 40" className="w-6 h-6 fill-current">
                        <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z">
                        </path>
                    </svg>

                    <p className="mx-3">{message}</p>
                </div>

                <button  onClick={onClose} type='button' className="p-1 transition-colors duration-300 transform rounded-md hover:bg-opacity-25 hover:bg-gray-600 focus:outline-none">
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
        </div>
        : null}
    </>
  )
}
