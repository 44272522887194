import React from 'react'

export const TextInput = ({className, label, value, setValue, type = 'text', disabled, placeholder, rows = 3, errorMsg = null, step, defaultVal}) => {

    const onChangeHandler = (e) => {
        setValue(e.target.value)
    }

  return (
    <div className={className}>
        {label ? <label className="text-gray-700 dark:text-gray-200 mb-2">{label}</label> : null}
        {type !== 'textarea' ? 
         <input defaultValue={defaultVal} value={value} step={step} onChange={onChangeHandler} type={type} className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" disabled={disabled} placeholder={placeholder} />
        : <textarea defaultValue={defaultVal} rows={rows} value={value} onChange={onChangeHandler} type={type} className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" disabled={disabled} placeholder={placeholder} />}
        {errorMsg ? <p className="text-sm text-red-400">{errorMsg}</p> : null}
    </div>
  )
}
