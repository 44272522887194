import React from 'react'
import { readableDateWithWeek } from '../../../lib/helpers/dates'

export const DashboardHeader = ({headerData, completedOrders, pendingOrders, itemsServed, paymentCollected}) => {
  return (
    <div className='mb-12'>
        <p className='flex items-center gap-3 mb-6'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
            </svg>
            <span>{readableDateWithWeek(new Date())}</span>
        </p>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>

            {/* COMPLETE ORDERS */}
            <div className='px-6 2xl:px-8 py-4 bg-green-500 text-white rounded-lg shadow-md dark:bg-gray-800 flex gap-4 items-center'>
                <span className='flex items-center justify-center bg-white/20 rounded-full w-14 h-14 text-3xl flex-shrink-0'>
                    <i className="fa-solid fa-receipt"></i>
                </span>
                <div>
                    <p className='text-3xl'>{headerData.completedOrders}</p>
                    <p className='text-sm'>Orders Complete</p>
                </div>
            </div>
            {/* COMPLETE ORDERS */}

            {/* PENDING ORDERS */}
            <div className='px-6 2xl:px-8 py-4 bg-amber-500 text-white rounded-lg shadow-md dark:bg-gray-800 flex gap-4 items-center'>
                <span className='flex items-center justify-center bg-white/20 rounded-full w-14 h-14 text-3xl flex-shrink-0'>
                    <i className="fa-regular fa-hourglass-half"></i>
                </span>
                <div>
                    <p className='text-3xl'>{headerData.pendingOrders}</p>
                    <p className='text-sm'>Orders Pending</p>
                </div>
            </div>
            {/* PENDING ORDERS */}


            {/* ITEMS SERVED */}
            <div className='px-6 2xl:px-8 py-4 bg-rose-500 text-white rounded-lg shadow-md dark:bg-gray-800 flex gap-4 items-center'>
                <span className='flex items-center justify-center bg-white/20 rounded-full w-14 h-14 text-3xl flex-shrink-0'>
                    <i className="fa-solid fa-utensils"></i>
                </span>
                <div>
                    <p className='text-3xl'>{headerData.itemsServed}</p>
                    <p className='text-sm'>Items Served</p>
                </div>
            </div>
            {/* ITEMS SERVED */}

            {/* PAYMENTS RECEVED */}
            <div className='px-6 2xl:px-8 py-4 bg-purple-500 text-white rounded-lg shadow-md dark:bg-gray-800 flex gap-4 items-center'>
                <span className='flex items-center justify-center bg-white/20 rounded-full w-14 h-14 text-3xl flex-shrink-0'>
                    <i className="fa-solid fa-indian-rupee-sign"></i>
                </span>
                <div>
                    <p className='text-3xl'>{headerData.paymentCollected}</p>
                    <p className='text-sm'>Payment collected</p>
                </div>
            </div>
            {/* PAYMENTS RECEVED */}



        </div>
    </div>
  )
}
