import React from 'react'

export const Modal = ({onClose = () => null, className = '', children}) => {
  return (
    <div className='z-[997] w-screen h-screen fixed left-0 top-0 flex justify-center items-center'>
        <div className='z-[998] w-screen h-screen fixed left-0 top-0 bg-black/30' onClick={onClose}></div>
        <div className={`relative z-[999] ${className}`}>
            {children}
        </div>
    </div>
  )
}
