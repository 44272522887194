import React from 'react'
import { WaiterForm } from '../../../components/pages/waiters/WaiterForm'

export const AddWaiter = () => {
  return (
    <div className="container mx-auto p-6">
    <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Add New Waiter</h2>
        <WaiterForm />
    </div>
  )
}
