import { getJSON, patchJSON } from "."


const settingServer = {
    async getAll(){
        const response = await getJSON('/settings');
        return response
    },
    async save(data){
        const response = await patchJSON('/settings', data);
        return response
    }
}

export default settingServer