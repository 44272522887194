import React from 'react'
import { NavLink } from 'react-router-dom'

export const TablesPageHeader = ({count, status, onStatusChange}) => {
  return (
    <>
      <div className="sm:flex sm:items-center sm:justify-between mb-6">
      <div>
          <div className="flex items-center gap-x-3">
              <h2 className="text-lg font-medium text-gray-800 dark:text-white">Tables</h2>

              <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">{count} total</span>
          </div>
      </div>

      <div className="flex items-center gap-x-3">

          <NavLink to='/management/tables/add' className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <span>Add table</span>
          </NavLink>
      </div>
    </div>

    <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg dark:bg-gray-900 rtl:flex-row-reverse dark:border-gray-700 dark:divide-gray-700">
      <button onClick={() => onStatusChange(1)} className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${status === 1 ? 'bg-gray-100' : ''} sm:text-sm dark:bg-gray-800 dark:text-gray-300`}>
          View all
      </button>

      <button onClick={() => onStatusChange(-1)} className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${status === -1 ? 'bg-gray-100' : ''} sm:text-sm dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100`}>
          Trash
      </button>
    </div>
    </>
  )
}
