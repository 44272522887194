import React from 'react'
import { ItemForm } from '../../../components/pages/items/ItemForm'

export const AddItem = () => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Add New Item</h2>
      <ItemForm />
    </div>
  )
}
