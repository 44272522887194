import React, { useId } from 'react'

export const CheckBox = ({className, label, checked, setChecked}) => {

    const id = useId()

    const onChangeHandler = () => {
        setChecked(!checked)
    }

  return (
    <div className={`flex ${className}`}>
        <input checked={checked} onChange={onChangeHandler} type="checkbox" id={id} className="w-4 h-4 text-blue-500 bg-gray-100 border-gray-300 rounded-md focus:ring-blue-400 dark:focus:ring-blue-600 dark:ring-offset-blue-300 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
        <label htmlFor={id} className="ms-2 text-sm text-gray-700 dark:text-gray-200">{label}</label>
    </div>
  )
}
