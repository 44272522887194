import React, { useContext, useEffect, useState } from 'react'
import { TablesGrid } from '../../../components/pages/tables/TablesGrid'
import tableServer from '../../../lib/server/table'
import { TablesPageHeader } from '../../../components/pages/tables/TablesPageHeader'
import { ToastContext } from '../../../contexts/ToastContext'

export const Tables = () => {


  const [tables, setTables] = useState([])
  const {toastSuccess, toastError} = useContext(ToastContext)

  const [status, setStatus] = useState(1)

  const fetchTables = async (s = status) => {
    try{
      const response = await tableServer.all(s)
      setTables(response)
    }catch(e){
      console.log(e)
      toastError('Unable to fetch tables! Please refresh the page.')
    }
  }

  useEffect(() => {
    (async () => {
      await fetchTables()
    })()
  }, [])


  const onDeleteHandler = async (table) => {

    const result = window.confirm('Are you sure to delete the table?')
    if(!result){
      return
    }
    try{
      await tableServer.delete(table.id)
      await fetchTables()
      toastSuccess('Table deleted successfully!')
    }catch(e){
      console.log(e)
      toastError('Unable to delete table! Please refresh the page.')
    }
  }

  const statusChangeHandler = async (s) => {
    setStatus(s)
    await fetchTables(s)
  }

  const onRestoreHandler = async (item) => {
    try{
      await tableServer.restore(item.id)
      await fetchTables()
      toastSuccess('Table restored successfully!')
    }catch(e){
      console.log(e)
      toastError('Unable to restore table! Please try again.')
    }
  }

  return (
    <div className="container mx-auto p-6">
      <TablesPageHeader count={tables.length} status={status} onStatusChange={statusChangeHandler} />
      <TablesGrid tables={tables} onDelete={onDeleteHandler} onRestore={onRestoreHandler} />
    </div>
  )
}
