import React, { useContext, useEffect, useState } from 'react'
import { ItemForm } from '../../../components/pages/items/ItemForm'
import { ToastContext } from '../../../contexts/ToastContext'
import itemServer from '../../../lib/server/item'
import { useParams } from 'react-router-dom'

export const UpdateItem = () => {
    
  const [item, setItem] = useState(null)
  const {toastError} = useContext(ToastContext)

  const {id} = useParams()

  useEffect(() => {
    (async () => {
        try{
            const response = await itemServer.get(id)
            setItem(response)
        }catch(e){
            console.log(e)
            toastError('Unable to find item! Please refresh the page.')
        }
    })()
  }, [])

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Update Item</h2>
      {item ? <ItemForm item={item} /> : null}
    </div>
  )
}
