import React, { useContext, useState } from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import { SearchContext } from '../../contexts/SearchContext';
import { UserContext } from '../../contexts/UserContext';

export const Navbar = () => {
    const {setSearchTerm} = useContext(SearchContext)
    const [localTerm, setLocalTerm] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const {user, setUser} = useContext(UserContext)
    const navigate = useNavigate()
    const location = useLocation()

    const searchEnterHandler = (e) => {
        if(e.key === 'Enter'){
            setSearchTerm(localTerm)
            if(location.pathname !== '/search'){
                navigate('/search')
            }
        }
       
    }

    const logOutHandler = () => {
        localStorage.removeItem('_auth')
        setUser()
        navigate('/login')
    }

    return (
        <nav className="relative bg-white shadow dark:bg-gray-800">
            <div className="container px-6 py-3 mx-auto">
                <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <NavLink to="/">
                                <img className="w-auto h-6 sm:h-7" src="/logo.png" alt="" />
                            </NavLink>

                            {/* Search input on desktop screen */}
                            <div className="hidden mx-10 lg:block">
                                <div className="relative">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <svg className="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                                            <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <input type="text" onKeyUp={searchEnterHandler} value={localTerm} onChange={e => setLocalTerm(e.target.value)} className="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300" placeholder="Search" />
                                </div>
                            </div>
                        </div>

                        {/* Mobile menu button */}
                        <div className="flex lg:hidden">
                            <button onClick={() => setIsOpen(!isOpen)} type="button" className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400" aria-label="toggle menu">
                                {!isOpen ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>

                    {/* Mobile Menu open: "block", Menu closed: "hidden" */}
                    <div className={`absolute inset-x-0 z-20 w-full px-6 py-2 transition-all duration-300 ease-in-out bg-white top-24 dark:bg-gray-800 lg::mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center ${isOpen ? 'translate-x-0 opacity-100' : 'opacity-0 -translate-x-full'}`}>
                        <div className="flex flex-col lg:flex-row lg:mx-1">
                            <NavLink to="/" className="my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:mx-4 lg:my-0">Dashboard</NavLink>
                            <NavLink to="/orders" className="my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:mx-4 lg:my-0">Orders</NavLink>
                            <NavLink to="/management" className="my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:mx-4 lg:my-0">Management</NavLink>
                            <NavLink to="/reports" className="my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:mx-4 lg:my-0">Reports</NavLink>
                            <NavLink to="/profile" className="my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:mx-4 lg:my-0">{user.name}</NavLink>
                            <NavLink to="/settings" className="my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:mx-4 lg:my-0">Settings</NavLink>
                            <button onClick={logOutHandler} className="my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-red-600 dark:hover:text-red-400 hover:underline lg:mx-4 lg:my-0">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
                                </svg>
                            </button>
                        </div>

                        {/* Search input on mobile screen */}
                        <div className="my-4 lg:hidden">
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg className="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                                        <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <input onKeyUp={searchEnterHandler} value={localTerm} onChange={e => setLocalTerm(e.target.value)} type="text" className="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300" placeholder="Search" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-3 mt-3 -mx-3 overflow-y-auto whitespace-nowrap scroll-hidden">
                    {location.pathname.startsWith('/management') ? 
                    <>
                        <NavLink to="/management/items" className="mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:my-0">Items</NavLink>
                        <NavLink to="/management/waiters" className="mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:my-0">Waiters</NavLink>
                        <NavLink to="/management/tables" className="mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:my-0">Tables</NavLink>
                    </>
                    : null}
                    {location.pathname.startsWith('/orders') ? 
                    <>
                        <NavLink to="/orders" className="mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:my-0">Orders</NavLink>
                        <NavLink to="/orders/create" className="mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline lg:my-0">Create Order</NavLink>
                    </>
                    :null}
                    {/* Rest of the links */}
                </div>
            </div>
        </nav>
    );
}
