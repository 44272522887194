import React from 'react'
import { NavLink } from 'react-router-dom'

const ResultTable = ({table}) => {
    return (
        <div className='flex gap-3 items-center'>
            <div className='text-2xl'>
                <i className="fa-solid fa-chair"></i>
            </div>
            <NavLink to={`/management/tables/${table.id}`}>{table.table_code}</NavLink>
        </div>
    )
}

export const TablesSearchResult = ({tables}) => {
  return (
    <div>
        <p className='text-lg font-medium border-b border-zinc-300 mb-6'>Tables</p>
        <div className='flex flex-col gap-3'>
            {tables.map(item => <ResultTable key={item.id} table={item} />)}
        </div>
    </div>
  )
}
