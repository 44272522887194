import { baseUrl, deleteJSON, getJSON, patchJSON, postMultipart } from "."


const waiterServer = {
    async create(data){
        const response = await postMultipart('/waiters', data);
        return response.data
    },

    async all(status){
        const response = await getJSON(`/waiters?status=${status}`);
        return response
    },

    image(id){
        return `${baseUrl}/waiters/image/${id}`;
    },

    async delete(id){
        const response = await deleteJSON(`/waiters/${id}`)
        return response
    },

    async get(id){
        const response = await getJSON(`/waiters/${id}`)
        return response
    },

    async update(id, data){
        const response = await postMultipart(`/waiters/${id}`, data);
        return response.data
    },

    async restore(id){
        const response = await patchJSON(`/waiters/${id}/restore`)
        return response
    }
}

export default waiterServer