import React from 'react'
import { NavLink } from 'react-router-dom'
import waiterServer from '../../lib/server/waiter'

const ResultWaiter = ({waiter}) => {
    return (
        <div className='flex gap-3 items-center'>
            <img src={waiterServer.image(waiter.id)} className='w-12 h-12 rounded-full object-cover object-center' alt="" />
            <NavLink to={`/management/waiters/${waiter.id}`}>{waiter.name}</NavLink>
        </div>
    )
}

export const WaitersSearchResult = ({waiters}) => {
  return (
    <div className='mb-10'>
        <p className='text-lg font-medium border-b border-zinc-300 mb-6'>Waiters</p>
        <div className='flex flex-col gap-3'>
            {waiters.map(item => <ResultWaiter key={item.id} waiter={item} />)}
        </div>
    </div>
  )
}
