import React, { useContext, useEffect, useId, useState } from 'react'
import { SelectInput } from '../../inputs/SelectInput'
import { TextInput } from '../../inputs/TextInput'
import { Modal } from '../../box/Modal'
import Case from 'case'
import { SettingContext } from '../../../contexts/SettingContext'


const OrderViewItem = ({serial, item, changeQuantity}) => {
    return (
        <div className='flex items-start text-sm py-1'>
            <span className='w-8'>{serial}</span>
            <div className='w-full'>
                <span className='w-full'>{item.title}</span>
                <div className='flex items-center gap-1'>
                    <button className='p-1' onClick={() => changeQuantity(item.id, item.count + 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </button>
                    {item.count}
                    <button className='p-1' onClick={() => changeQuantity(item.id, item.count - 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                        </svg>
                    </button>
                </div>
            </div>
            <span className='flex-shrink-0'>₹ {item.price * item.count}</span>
        </div>
    )
}

const PaymentItem = ({item, onRemove}) => {
    return (
        <div className='flex justify-between [&>div>button]:hidden [&>div>button]:hover:inline-block'>
            <div className='w-fit flex items-center gap-1'>
                <span>Paid ({Case.title(item.method)})</span>
                <button className='hover:text-red-500' onClick={onRemove}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <span>₹ {item.amount}</span>
        </div>
    )
}

const PaymentForm = ({setActive, totalAmount, addPayment, payments}) => {

    const [amount, setAmount] = useState(0)
    const [method, setMethod] = useState('cash')
    const {settings} = useContext(SettingContext)

    const calculte = () => {
        let newAmount = totalAmount;
        for(let i in payments){
            newAmount = newAmount - payments[i].amount
        }
        setAmount(newAmount)
    }

    useEffect(() => {
        calculte()
    }, [totalAmount, payments])

    const onSubmitHandler = (e) => {
        e.preventDefault()
        addPayment({amount, method})
        setActive(false)
    }

    return (
        <Modal onClose={() => setActive(false)} className={`max-w-lg w-full px-8 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800`}>
            <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Add Payment</h2>
                <button className={`py-1.5 px-2`} onClick={() => setActive(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>

            <form onSubmit={onSubmitHandler}>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                    <SelectInput options={JSON.parse(settings ? settings.PAYMENT_METHODS : '[]').map(item => {return {label: item, value: item}})} value={method} setValue={setMethod} label={'Method'} />
                    <TextInput type='number' value={amount} setValue={setAmount} label={'Amount'} />
                </div>

                <div className="flex gap-2 mt-6">
                    <button className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Paid</button>
                    <button onClick={() => setActive(false)} type='button' className='px-8 py-2.5 leading-5 text-gray-600 transition-colors duration-300 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100 flex items-center gap-2'>Close</button>
                </div>
            </form>
        </Modal>
    )
}

export const OrderView = ({setAddNote, note, items, changeQuantity, totalAmount, finalAmount, addPayment, payments, onPaymentRemove, onSubmit, orderData, discount, setDiscount, status, setStatus}) => {

  const [active, setActive] = useState(false)
  const {settings} = useContext(SettingContext)
  const payId = useId()

  const onPrintHanlder = () => {
    window.open(window.location.origin + `/orders/print/${orderData.id}`, '_blank');
  }
    
  return (
    <div className='px-8 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800 h-full min-h-[60vh] flex flex-col'>
        {active ? <PaymentForm setActive={setActive} totalAmount={finalAmount} addPayment={addPayment} payments={payments} /> : null}
        <div className='mb-4'>
            <h2 className="text-lg font-medium text-gray-800 dark:text-white">Order Items</h2>
            {orderData?.table ? <p className='text-sm text-zinc-500'>Table {orderData?.table.table_code}</p> : null}
            <button className='text-sm w-full text-left' onClick={setAddNote}>{note ? `Note: ${note}` : 'Add note'}</button>
        </div>
        <hr />
        <div className='mt-4 h-full  overflow-y-auto'>
            {Object.keys(items).map((keyName, index) => <OrderViewItem key={items[keyName].id} serial={index+1} item={items[keyName]} changeQuantity={changeQuantity} />)}
        </div>
        <div className='mt-4 flex-shrink-0'>
            <div className='flex justify-between items-center text-xs'>
                <span>Sub Total</span>
                <span>₹ {totalAmount}</span>
            </div>
            {settings ? <>
                {settings.SGST !== '0' ? 
                <div className='flex justify-between items-center text-xs'>
                    <span>SGST @{settings.SGST}%</span>
                    <span>₹ {((totalAmount * parseFloat(settings.SGST)) / 100).toFixed(2)}</span>
                </div> : null}
                {settings.CGST !== '0' ? 
                <div className='flex justify-between items-center text-xs'>
                    <span>CGST @{settings.CGST}%</span>
                    <span>₹ {((totalAmount * parseFloat(settings.CGST)) / 100).toFixed(2)}</span>
                </div> : null}
            </> : null }
            <div className='flex items-center justify-between gap-3'>
                <p className='flex-shrink-0 text-xs'>Discount (₹)</p>
                <input type="number" className='border rounded text-xs py-1 px-2 w-full max-w-24 focus:outline-none text-end' value={discount} onChange={e => setDiscount(e.target.value)} />
            </div>
            <div className='flex justify-between items-center font-medium text-lg'>
                <span>Total</span>
                <span>₹ {Math.round(finalAmount)}</span>
            </div>
            <div className='flex gap-2 items-center'>
                <input type="checkbox" checked={status} onChange={(e) => setStatus(e.target.checked)} id={payId} /> <label htmlFor={payId}>Paid</label>
            </div>
        </div>
        <div className='mt-4'>
            {payments.map((item, index) => <PaymentItem item={item} key={index} onRemove={() => onPaymentRemove(index)} />)}
        </div>
        <div className='mt-4 flex-shrink-0 flex gap-2 items-center'>
            <button disabled={Object.keys(items).length === 0} onClick={onSubmit} className="px-6 py-2 w-full font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 disabled:bg-blue-300">
                Save
            </button>
            <button onClick={onPrintHanlder} disabled={!orderData} className='px-6 py-2 w-full font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 disabled:bg-blue-300'>
                Print
            </button>
        </div>
    </div>
  )
}
