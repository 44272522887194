import { getJSON } from "."

const managementServer = {

    async fetchData(){
        const response = await getJSON('/management')
        return response
    }
    
}

export default managementServer