import React, { useContext, useEffect, useState } from 'react'
import { ItemsPageHeader } from '../../../components/pages/items/ItemsPageHeader'
import { ItemsTable } from '../../../components/pages/items/ItemsTable'
import itemServer from '../../../lib/server/item'
import { TablePegination } from '../../../components/footers/TablePegination'
import { ToastContext } from '../../../contexts/ToastContext'
const itemsPerPage = 5
export const Items = () => {


  const [itemsData, setItemsData] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [query, setQuery] = useState({start: 0, end: itemsPerPage})
  const [status, setStatus] = useState(1)

  const {toastError, toastSuccess} = useContext(ToastContext)


  const fetchItems = async (statusVal = status) => {
    try{
      const response = await itemServer.all(statusVal)
      setItemsData(response)
      const result = response.filter(item => item.title.toLowerCase().includes(searchTerm))
      setFilteredItems(result)
    }catch(e){
        console.log(e)
    }
  }

  useEffect(() => {
    (async () => {
      await fetchItems()
    })()
  }, [])


  const pageChangeHandler = (start, end) => {
    setQuery({start, end})
  }

  const onSearchHandler = (term) => {
    setSearchTerm(term)
    const result = itemsData.filter(item => item.title.toLowerCase().includes(term))
    setFilteredItems(result)
  }


  const onDeleteHandler = async (item) => {
    const result = window.confirm('Are you sure to delete the item?')
    if(!result){
      return
    }
    try{
      await itemServer.delete(item.id)
      await fetchItems()
      toastSuccess('Item deleted successfully!')
    }catch(e){
      console.log(e)
      toastError('Unable to delete item! Please try again.')
    }
  }

  const onStatusChangeHandler = async (val) => {
    setStatus(val)
    await fetchItems(val)
  }

  const onRestoreHandler = async (item) => {
    try{
      await itemServer.restore(item.id)
      await fetchItems()
      toastSuccess('Item restored successfully!')
    }catch(e){
      console.log(e)
      toastError('Unable to restore item! Please try again.')
    }
  }

  return (
    <section className="container p-6 mx-auto">
       <ItemsPageHeader onStatusChange={onStatusChangeHandler} count={itemsData.length} searchTerm={searchTerm} onSearch={onSearchHandler} status={status}  />
       <ItemsTable items={filteredItems.slice(query.start, query.end)} onDelete={onDeleteHandler} onRestore={onRestoreHandler} />
       <TablePegination total={filteredItems.length} itemsPerPage={itemsPerPage} nextPage={pageChangeHandler} prevPage={pageChangeHandler} />
   </section>
  )
}
